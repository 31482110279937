import React from 'react';
import { Box, Image, Typography } from '../../../components/ui';
import styles from './attendeesList.module.css';
import Container from '../../../components/ui/container';
import { useDispatch } from 'react-redux';
import * as attendeesActions from './../../../reducers/attendees/actions';
import { IonCard } from '@ionic/react';
import { useHistory } from 'react-router';

interface AttendeeCardProps {
  nome?: string;
  documento?: string;
  nome_bilhete?: string;
  nome_ingresso?: string;
  cpf?: string;
  codigo?: string;
  data_credenciamento?: string;
  cancelado?: any;
  email?: string;
  telefone?: string;
}

const AttendeeCard: React.FC<AttendeeCardProps> = ({
  nome,
  nome_bilhete,
  cpf,
  codigo,
  data_credenciamento,
  cancelado,
  email,
  telefone,
  nome_ingresso,
}) => {
  const dispatch = useDispatch();

  // const cardAttendee = {
  //   cpf: cpf,
  //   cpfNumber: cpf,
  //   nome: nome,
  //   nome_bilhete: nome_bilhete,
  //   nome_ingresso: nome_ingresso,
  //   codigo: codigo,
  //   data_credenciamento: data_credenciamento,
  //   cancelado: cancelado,
  //   email: email,
  //   telefone: telefone,
  //   redirect: '/searchAttendee',
  // };

  // const setAttendee = (attendee: AttendeeCardProps) =>
  //   dispatch(attendeesActions.attendee(attendee));

  // const history = useHistory();

  // const handleEditAttendee = () => {
  //   setAttendee(cardAttendee);
  //   setTimeout(() => {
  //     history.push('/updateAttendee');
  //   }, 400);
  // };

  const cardAttendee = {
    cpf: cpf,
    cpfNumber: cpf,
    nome: nome,
    nome_bilhete: nome_bilhete,
    nome_ingresso: nome_ingresso,
    codigo: codigo,
    data_credenciamento: data_credenciamento,
    cancelado: cancelado,
    email: email,
    telefone: telefone,
    redirect: '/searchAttendee',
  };

  const setAttendee = (attendee: AttendeeCardProps) =>
    dispatch(attendeesActions.attendee(attendee));

  const history = useHistory();

  const handleCheckin = (event: React.MouseEvent) => {
    console.log('1');

    event.stopPropagation(); // Prevents the click from bubbling up to the IonCard
    console.log('2');
    setAttendee(cardAttendee);
    dispatch(attendeesActions.checkin(cardAttendee));
    dispatch(attendeesActions.attendee(cardAttendee));
    setTimeout(() => {
      dispatch(attendeesActions.search(cardAttendee.codigo));
    }, 500);
  };

  const handleEditAttendee = () => {
    setAttendee(cardAttendee);
    setTimeout(() => {
      history.push('/updateAttendee');
    }, 400);
  };

  return (
    <IonCard className={styles.container} onClick={handleEditAttendee}>
      <Box className={styles.leftBodyCard}>
        <Typography tag='p' className={styles.paragraph}>
          {nome}
        </Typography>
        <Typography tag='p' className={styles.smallParagraph}>
          {nome_bilhete || nome_ingresso}
        </Typography>
        <Typography tag='p' className={styles.documentParagraph}>
          Documento: {cpf}
        </Typography>
        <Typography tag='p' className={styles.ticketNumberParagraph}>
          Nº do ingresso: {codigo}
        </Typography>
      </Box>
      <Box className={styles.rightBodyCard}>
        {!data_credenciamento && !cancelado ? (
          <Typography
            className={styles.doCheckin}
            tag='p'
            onClick={(event) => handleCheckin(event)}
          >
            Fazer check-in
          </Typography>
        ) : null}
        {data_credenciamento !== null && (
          //
          <Image
            src={'/assets/icon/success.svg'}
            width='24px'
            height='24px'
            alt='errororsuccess'
          />
        )}
        {cancelado && (
          <Image
            src={'/assets/icon/error.svg'}
            width='24px'
            height='24px'
            alt='errororsuccess'
          />
        )}
      </Box>
    </IonCard>
  );
};

export default AttendeeCard;
